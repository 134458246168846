@import "colors.scss";

.pi {
    font-family: 'primeicons' !important;
}

.p-listbox {
    border: none;
    border-radius: 0;
    margin-top: 1.375rem;
}

.p-listbox .p-listbox-list .p-listbox-item	{
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
    padding: 0.688rem 2.5rem 0.813rem 1.5rem;
}

.p-listbox .p-listbox-header {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-left: 0;
    padding-right: 0;
}

.p-listbox-header span {
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 400;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    background-color: #E9F6F8;
    color: #000;
}

.p-inputtext:enabled {
    &:focus {
        border-color: #727475;
        box-shadow: none;
    }
    &:hover {
        border-color: #727475;
    }
}

.p-checkbox, .p-checkbox .p-checkbox-box {
    width: 24px;
    height: 24px;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background-color: #1EA2B1;
    border-color: #1EA2B1;
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
    box-shadow: none;
}

.custom-dd__no-bg {
    .p-dropdown, .p-dropdown:not(.p-disabled):hover {
        background-color: transparent;
        border-color: #FFF;
        border-width: 0.125rem;
        border-radius: 0.188rem;
    }

    .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: none;
        border-color: #FFF;
    }

    .p-dropdown-trigger {
        color: #FFF;
    }

    .p-dropdown-label {
        color: #FFF;
        padding-left: 0.75rem;
        border: none;
    }

    .p-dropdown-label.p-placeholder {
        color: #FFF;
    }

    label {
        color: #FFF;
        left: 0.75rem;
    }

    input:focus ~ label,
    input.p-filled ~ label,
    textarea:focus ~ label,
    textarea.p-filled ~ label,
    .p-inputwrapper-focus ~ label,
    .p-inputwrapper-filled ~ label {
        top: 0;
        left: 0.5rem;
        background-color: #00738B;
        padding: 0 0.438rem;
        font-size: 0.875rem;
    }

    .p-dropdown-items .p-dropdown-item {
        border-bottom: 0.063rem solid;
        border-color: $eon-ultralightgrey;
    }

    .p-dropdown-items .p-dropdown-item.p-highlight {
        background-color: $eon-turquoise;
    }

    .p-dropdown .p-dropdown-clear-icon {
        color: #FFF;
    }
}

.default-switch__black, .default-switch__turquoise {
    width: 2.5rem;
    height: 1.5rem;
    display: flex !important;

    .p-inputswitch-slider {
        background: none;
        border: 2px solid #8F9192;

        &:before {
            background-color: #8F9192;
            width: 1rem;
            height: 1rem;
            margin-top: -0.5rem;
        }
    }

    &:not(.p-disabled):hover .p-inputswitch-slider {
        background: none;
    }

    &.p-focus .p-inputswitch-slider {
        box-shadow: none;
    }

    &.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
        background: none;
    }
}

.default-switch__black {

    &.p-inputswitch-checked .p-inputswitch-slider {
        background: none;
        border-color: #000;

        &:before {
            background-color: #000;
            transform: translateX(0.9rem);
        }
    }
}

.default-switch__turquoise {

    &.p-inputswitch-checked .p-inputswitch-slider {
        background: none;
        border-color: #1EA2B1;

        &:before {
            background-color: #1EA2B1;
            transform: translateX(0.9rem);
        }
    }
}

.custom-listbox {
    .p-listbox-list .p-listbox-item-group {
        padding: 0.857rem 1.5rem;
    }

    &__height-55vh {
        .p-listbox-list-wrapper {
            //height: 55vh;
            height: calc(var(--vh, 1vh) * 50);
        }
    }
    &__height-85vh {
        .p-listbox-list-wrapper {
            //height: 85vh;
            height: calc(var(--vh, 1vh) * 85);
        }
    }
}

.custom-mm {
    .p-multiselect {
        width: 17.5rem;
        height: 2.75rem;
        max-height: 2.75rem;
        max-width: 100%;
        border-radius: 0.188rem;
        border: 0.125rem solid #8F9192;
    }

    .p-multiselect:not(.p-disabled):hover {
        border-color: #727475;
    }

    .p-multiselect-label {
        color: #39393A;
        padding: 0.688rem;
    }
    .p-multiselect-label, label {
        padding-left: 0.75rem;
        font-size: 1.125rem;
    }

    .p-multiselect-panel .p-multiselect-items,
    .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
        background: none;
        color: #39393A;
    }

    .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
        box-shadow: none;
    }

    &.p-float-label .p-inputwrapper-focus ~ label,
    &.p-float-label .p-inputwrapper-filled ~ label {
        top: 0;
        background-color: #fff;
        padding: 0 0.438rem;
        font-size: 0.875rem;
    }

    .p-multiselect-footer {
        padding: 1rem 0;
        display: flex;
        justify-content: space-evenly;
    }
}

.custom-calendar-picker {
    .p-calendar {
        > .p-inputtext {
            min-width: 13rem;
            height: 2.75rem;
            max-height: 2.75rem;
            width: 100%;
            border-radius: 0.188rem;
            border: 0.125rem solid #8F9192;
            font-size: 1.125rem;
            color: #666666;
        }

        .p-datepicker {
            min-width: 19rem;
        }

        .p-datepicker table th {
            padding: 0.2rem;
        }

        .p-datepicker table td {
            padding: 0.2rem;

            > span {
                width: 2rem;
                height: 2rem;
            }
        }
    }
}

.p-inputtext {
    border: 0.063rem solid #8F9192;
    height: 2.25rem;
    border-radius: 0.188rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
}

.p-inputswitch {
    margin: 0 0.313rem;
}

.small-input {
    &.p-inputtext {
        width: 55px;
    }
}

.custom-no-bg.p-tabview {
    .p-tabview-nav-container {
        padding: 0 1.5rem;
    }

    .p-tabview-panels {
        background: none;
        padding: 0;
        border: none;
        border-radius: unset;
        color: inherit;
    }

    .p-tabview-nav {
        border-bottom: 0.25rem solid #E8E8E8;
        margin: 0 0 1.5rem 0;

        li {
            font-size: 1.125rem;
            line-height: 2rem;

            img {
                margin-right: 7px;
            }
        }

        li .p-tabview-nav-link {
            font-weight: 300;
        }

        li .p-tabview-nav-link,
        li .p-tabview-nav-link:not(.p-disabled):focus {
            box-shadow: none;
            background: none;
            border: none;
            border-radius: 0;
            color: #39393A;
        }

        li.p-highlight .p-tabview-nav-link {
            border-bottom: 0.25rem solid #39393A !important;
            margin-bottom: -0.25rem;
            font-weight: 700;
        }

        li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
            background: none;
            border: none;
            border-bottom: 0.25rem solid #39393A !important;
            margin-bottom: -0.25rem;
        }
    }
}

.custom-accordion.p-accordion {

    .p-accordion-header .p-accordion-header-link {
        border: none;
        background: none;
        color: #39393A;
        font-weight: 300;
    }

    .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
        background: #E8E8E8;
        border-color: #E8E8E8;
        color: #39393A;
    }

    .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
        box-shadow: none;
    }

    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link,
    .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
    .p-accordion-content {
        background: #F6F6F7;
        border: none;
    }

    .p-accordion .p-accordion-header .p-accordion-header-link {
        padding-left: 0;
        margin-bottom: 1rem;
    }

    .p-accordion .p-accordion-content {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    }
}

//fullcalendar
.fc .fc-timegrid-slot {
    height: 0.5em !important;
}

.fc.fc-theme-standard .fc-view-harness td {
    font-size: .825em;
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-size: 1.125rem;
}

.cbms-generic-table-style {
    .cbms-chips {
        font-size: inherit;
    }

    .pi-sort:before {
        content: "\e99e";
    }

    .pi-sort-down:before {
        content: "\e9a0";
    }

    .pi-sort-up:before {
        content: "\e9a2";
    }

    button.mat-flat-button {
        border: 1px solid $eon-darkgrey-25;
    }
    button.mat-flat-button:hover {
        border: 1px solid #fff;
    }

    button.mat-button-disabled {
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
            0px 0px 0px 0px rgba(0, 0, 0, 0.14),
            0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }
    button.mat-button-disabled:hover {
        background-color: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
    }

    .p-datatable .p-datatable-thead > tr > th,
    .p-datatable .p-datatable-tbody > tr > td,
    .p-paginator {
        border: none;
    }

    .p-datatable .p-datatable-thead > tr > th {
        text-transform: uppercase;
        padding: 10px 0.857em;
        background-color: #e0e0e0;
        padding-left: 1.3em;
    }

    .p-table .p-table-caption,
    .p-table .p-table-summary,
    .p-datatable .p-datatable-header {
        background: none !important;
        border: none !important;
        padding: 0.571em 0 !important;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        padding-left: 1.3em;
        font-size: 0.8rem;
        display: block;

        &.button-column {
            a {
                text-decoration: none;
                color: #848484;
            }
        }
    }

    .p-datatable-scrollable-header-table {
        background-color: #e0e0e0;
    }

    .p-datatable .p-sortable-column.p-highlight,
    .p-datatable .p-sortable-column:not(.p-highlight):hover {
        background-color: #f4f4f4 !important;
        color: #333333 !important;
    }

    .p-datatable .p-datatable-tbody > tr:nth-child(even) {
        background-color: #f4f4f4;
    }

    .button-column {
        width: 180px;
    }

    .hide-column {
        display: none;
    }
}

.burger-menu {
    .p-button {
        background-color: #1ea2b1;
        border: 1px solid #1ea2b1;
    }
}

.p-button.p-button-icon-only.turquoise {
    height: 2.25rem;
    background: none;
    border-color: #1ea2b1;
    color: #1ea2b1;

    &:hover {
        background: #1ea2b1;
        color: #FFF;
    }
}

.p-progress-spinner-svg {
    top: 15px !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    white-space: pre-line;
}

.dd_min-width {
    .p-dropdown.p-component {
        min-width: 200px;
    }
}

@media (min-width: $screen-sm) {
    .small-input {
        &.p-inputtext {
            width: 80px;
        }
    }

    .custom-no-bg.p-tabview {
        .p-tabview-nav-container {
            padding: 0;
        }
        .p-tabview-nav {
            margin: 2rem 0 3rem 0;
        }
    }

    .dd_min-width {
        .p-dropdown.p-component {
            min-width: 300px;
        }
    }
}

.highcharts-reset-zoom {
    display: none !important;
}
body .p-button.p-button-text {
    color: #000;
    background: transparent;
    border-color: transparent;

    &:enabled:hover {
        color: #000;
        border-color: transparent;
        background: rgba(0,122,217,.04);
    }
}
// style for input switch
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: $eon-turquoise;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: $eon-turquoise;
}
