$eon-red: rgb(234, 28, 10);
$eon-white: rgb(255, 255, 255);
$eon-turquoise: rgb(30, 162, 177);
$eon-bordeaux: rgb(176, 4, 2);
$eon-limeyellow: rgb(227, 224, 0);
$eon-black: rgb(0, 0, 0);
$eon-darkgrey: rgb(57, 57, 58);

$eon-red-active: rgb(213, 22, 7);
$eon-bordeaux-active: rgb(160, 3, 17);
$eon-limeyellow-active: rgb(218, 214, 0);
$eon-turquoise-active: rgb(43, 145, 163);

$eon-red-75: rgb(240, 85, 72);
$eon-red-50: rgb(244, 141, 132);
$eon-red-25: rgb(250, 198, 194);

$eon-bordeaux-75: rgb(196, 67, 65);
$eon-bordeaux-50: rgb(215, 129, 128);
$eon-bordeaux-25: rgb(235, 192, 192);
$eon-bordeaux-dark: rgb(118, 2, 12);

$eon-limeyellow-75: rgb(234, 232, 64);
$eon-limeyellow-50: rgb(241, 239, 127);
$eon-limeyellow-25: rgb(248, 247, 191);

$eon-turquoise-75: rgb(86, 185, 197);
$eon-turquoise-50: rgb(142, 208, 216);
$eon-turquoise-25: rgb(199, 232, 235);
$eon-turquoise-dark: rgb(15, 115, 138);

$eon-darkgrey-active: rgb(64, 64, 64);
$eon-darkgrey-26: rgb(143, 145, 146);
$eon-darkgrey-27: rgb(124, 125, 126);

$eon-darkgrey-75: rgb(64, 64, 64);
$eon-darkgrey-25: rgb(191, 191, 191);

$eon-middlegrey: rgb(143, 145, 146);
$eon-lightgrey: rgb(232, 232, 232);
$eon-ultralightgrey: rgb(246, 246, 247);

$eon-darkerlightgrey: rgb(211, 211, 211);
$eon-green: rgb(0, 128, 0);
