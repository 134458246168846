$default-margin: 1.5rem;
// $app-margin: 32px;
// $small-margin: 6px;
// $big-margin: 32px;
// $input-mid-size: 250px;

$screen-sm: '576px';
$screen-md: '768px';
$screen-lg: '992px';
$screen-xl: '1200px';

$container-sm: 540px;
$container-md: 720px;
$container-lg: 960px;
$container-xl: 1200px;
