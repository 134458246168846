@import "font";
@import "constants";
@import "ui-libraries-overwrites";
@import "toggle-switch";
@import "colors";

html, body {
    font-size: 16px !important;
    height: calc(var(--vh, 1vh) * 100);
    background: #F6F6F7;
}


* {
    font-family: $eon-brix-fontstack !important;
}

.noscroll {
    overflow: hidden;
    position: fixed;
}

.app-wrapper {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.cbms-redBg {
    background-color: rgb(244 77 77) !important;
    color: #FFF;
    padding: 5px 10px;
}

.cbms-yellowBg {
    background-color: rgb(243 240 76) !important;
    color: gray;
    padding: 5px 10px;
}


.app-header {
    height: 4rem;
    background-color: #00738B;
    color: #fff;

    .logo {
        margin: 1rem auto;
        display: flex;
        align-items: center;

        span {
            font-size: 1.125rem;
            font-weight: 700;
            line-height: 1.5rem;
            letter-spacing: 0;
            text-align: left;
            margin-left: 0.75rem;
        }
    }

    .main-nav-link {
        // flex-grow: 1;

        &.mobile-navigation {
            display: inline;
        }

        &.desktop-navigation {
            display: none;
        }

        a {
            height: 4rem;
            line-height: 4rem;
            padding: 1.25rem 0.5rem 1.125rem 0.5rem;
            font-size: 1rem;
            font-weight: 700;
            letter-spacing: 0;
            text-align: left;
            color: #fff;
            text-decoration: none;
            border-bottom: 0.25rem transparent;

            &:hover, &.selected {
                border-bottom: 0.25rem solid #FFF;
            }
        }
    }

    .app-header-right {

        .site-and-support {
            display: none;
        }
    }
}

.logout-overlay.p-overlaypanel {
    margin-top: 0;

    &:before, &::after {
        display: none;
    }
}

.app-sub-header {
    padding: 2rem 0;
    background: #fff;

    .mobile-navigation {
        display: flex;

        .icon-btn {
            cursor: pointer;

            &.selected {
                fill: #1EA2B1
            }
        }
    }

    .desktop-navigation {
        display: none;
    }
}

.container {
    margin: 0 auto;
    padding-right: 1rem;
    padding-left: 1rem;
}

.no-padding {
    padding: 0;
}

.mobile-view {
    display: flex;
}

.desktop-view {
    display: none;
}

.red-color {
    color: #EA1B0A
}

.gray-color {
    color: #8F9192
}

.black-color {
    color: #000;
}

.alert {
    color: #EA1B0A !important;
    border-color: #EA1B0A !important;
}

.margin-right-05 {
    margin-right: 0.5rem;
}

.margin-right-1 {
    margin-right: 1rem;
}

.margin-left-1 {
    margin-left: 1rem;
}

.margin-right-26 {
    margin-right: 1.625rem;
}

.margin-left-32 {
    margin-left: 2rem;
}

.margin-right-negativ-5 {
    margin-right: -5px;
}

.margin-left-auto {
    margin-left: auto;
}

.margin-bottom-1 {
    margin-bottom: 1rem;
}

.small-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
}

.input-on-write {
    background-color: #1EA2B1;
    border-color: #1EA2B1;
    color: #FFF;

    &:enabled:hover, &:enabled:focus {
        border-color: #1EA2B1;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.button-light-blue-bg {
    background-color: #1EA2B1;
    color: #FFF;
    border: 0.063rem solid #2B91A3;

    &:hover, &.selected {
        background-color: #F6F6F7;
        color: #000;
        border: 0.063rem solid #E8E8E8;
    }
}

.common-btn {
    background: transparent;
    border-color: transparent;
    font-weight: 700 !important;
    text-transform: uppercase;
    overflow: visible;
    line-height: 1.15;
    border-radius: 3px;
    font-size: 13px;
}

.no-border {
    border: none;
}
.button-turquoise-bg {
    background-color: $eon-turquoise;
    color: #FFF;
    border: 1px solid $eon-turquoise;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;

    &:hover {
        background-color: $eon-turquoise-dark !important;
    }
}

.button-gray-bg {
    background-color: #F6F6F7;
    color: #000;
    border: 0.063rem solid #E8E8E8;

    &:hover, &.selected {
        background-color: #1EA2B1;
        color: #FFF;
        border: 0.063rem solid #2B91A3;
    }

}

.disable-button {
    border-radius: 0 0.5rem 0.5rem 0;
    cursor: not-allowed !important;
    background-color: #F6F6F7;
    color: #000;
    border: 0.063rem solid #E8E8E8;
    &:hover, &.selected {
        cursor: not-allowed;
        background-color: #F6F6F7;
        color: #000;
        border: 0.063rem solid #E8E8E8;
    }
}

.sub-navigation {
    .grouped-buttons {
        button {
            font-weight: bold;
            font-size: 0.875rem;
            line-height: 1.5rem;
            width: 6.3rem;
            height: 3rem;

            span {
                font-weight: normal;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .grouped-button-left {
            border-radius: 0.5rem 0 0 0.5rem;
        }

        .grouped-button-right {
            border-radius: 0 0.5rem 0.5rem 0;
        }
    }
}

.page-title {
    font-weight: 900;
    font-size: 2.125rem;
    line-height: 2.125rem;
    margin-bottom: 2rem;
    margin-top: 0;
}

.main-wrapper {
    background-color: #F6F6F7;
    padding: 1rem 0;
    transition: all 500ms ease-in-out;
}

.margin-top70 {
    margin-top: 70px;
}

.search-wrapper {
    height: 0;
    opacity: 0;
    overflow: hidden;
    box-sizing: border-box;
    transition: all 500ms ease-in-out;
    position: absolute;
    left: 1rem;
    right: 1rem;
    margin: 0 auto;
    margin-top: 45px;
    max-width: 960px;

    &.isActive {
        height: 5rem;
        opacity: 1;
        padding: 0.5rem 0.125rem;
    }
}

.slide-in-box-wrapper {
    position: fixed;
    bottom: -120vh;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    overflow: hidden;
    z-index: 2;
    transition: bottom 500ms ease-in-out;

    &.isActive {
        bottom: 0;
    }

    .slide-in-box-body {
        padding: 5.25rem 1.5rem 0;
        max-height: calc(var(--vh, 1vh) * 60);
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;

        &__no-padding {
            padding: 0;
        }

        &__full-height {
            max-height: calc(var(--vh, 1vh) * 100);
            overflow: hidden;
        }
    }

    .slide-in-box-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 13.5rem;
        background-color: #1EA2B1;
        padding: 1.5rem;
        box-sizing: border-box;
    }
}

.slide-out-box-wrapper {
    position: fixed;
    top: -105vh;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    overflow: hidden;
    z-index: 2;
    transition: top 500ms ease-in-out;

    &.isActive {
        top: 0;
    }

    .slide-out-box-body {
        padding: 5.25rem 1.5rem 0;
        max-height: calc(var(--vh, 1vh) * 60);
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;

        &__no-padding {
            padding: 0;
        }

        &__full-height {
            max-height: calc(var(--vh, 1vh) * 100);
            overflow: hidden;
        }
    }
}

.close-button-top-right {
    position: absolute;
    top: 0.625rem;
    right: 1.25rem;
}

.entity-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFF;
    border-radius: 0.5rem;
    min-height: 8.5rem;
    width: 100%;

    &:hover {
        cursor: pointer;

        .entity-name {
            color: #1EA2B1 !important;
        }
    }

    .entity-card-content {
        display: flex;
        position: relative;
        padding: 1rem;
        align-items: center;

        .entity-icon {
            margin-right: 1rem;
        }

        .entity-details {
            flex-grow: 1;

            .entity-name {
                font-size: 1.25rem;
                line-height: 1.5rem;
                color: #39393A;
                margin: 0;
            }

            .entity-class {
                font-size: 1.125rem;
                color: #8F9192;
                line-height: 1.5rem;
                font-weight: 400;
                margin: 0;
            }
        }
    }

    .entity-card-footer {
        background-color: #E3E000;
        border-radius: 0 0 0.5rem 0.5rem;
        min-height: 4.3rem;
        padding: 1rem;

        .entity-kpi {
            line-height: 1.125rem;
            font-size: 1.125rem;
            font-weight: 400;
            color: #39393A;
            text-align: center;

            .kpi-value {
                font-weight: bold;
                font-size: 1.5rem;
                min-width: 8rem;
            }
        }
    }

    .top-right-position {
        position: absolute;
        top: 1.688rem;
        right: 2rem;
    }

}

.detailed-entity-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFF;
    min-height: 8.5rem;
    height: 100%;
    width: 100%;

    .entity-card-header {
        display: flex;
        position: relative;
        padding: 1rem;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .entity-icon {
            margin-right: 1rem;
        }

        .entity-details {
            flex-grow: 1;

            .entity-name {
                font-size: 1.25rem;
                line-height: 1.5rem;
                font-weight: 400;
                color: #39393A;
                margin: 0;
                overflow-wrap: break-word;
                word-break: break-word;

                a {
                    text-decoration: none;
                    color: #39393A;

                    &:hover {
                        color: #1EA2B1;
                    }
                }
            }

            .entity-class {
                font-size: 1.125rem;
                color: #8F9192;
                line-height: 1.5rem;
                font-weight: 400;
                margin: 0;
            }
        }
    }

    .entity-card-content {
        position: relative;
        padding: 1rem;
        align-items: center;
        color: #39393A;

        .entity-main {
            padding: 0 1rem;
            flex-grow: 1;
        }

        .first-col {
            width: 37%;
        }

        .mid-col {
            span.kpi-unit {
                width: 2rem;
                font-size: 0.875rem;
                padding-right: 0;
                overflow-wrap: break-word;
                text-align: right;
            }

            span.icon-btn {
                width: 2.5rem;
                text-align: right;
            }
        }

        .last-col {
            display: none;
        }

        .entity-row {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 1.125rem;
        }
    }

    .entity-card-footer {
        border-radius: 0 0 0.5rem 0.5rem;
        min-height: 3.5rem;
        padding: 1rem;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: auto;
    }
}

footer {
    background: #FFF;
    flex-shrink: 0;
}

.eonui-footer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 24px 25px;

    .eonui-logo-content {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        a {
            display: inline-block;
            padding: 0 10px;
            color: #39393A;
            font-size: 14px;
            line-height: 24px;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

    }

    .eonui-logo {
        display: flex;
        justify-content: flex-end;
        width: auto;
        margin-left: 30px;
        align-self: center;
    }
}

.eonui-copyright {
    font-family: EONBrixSans, Arial, Geneva, Helvetica, sans-serif;
    margin: 0px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    color: var(--eon-footer-bar-copyright-color);
}

.eonui-image-caption-container {
    .eonui-image-container {
        position: relative;
        width: auto;
        height: auto;
        overflow: hidden;
        display: block;

        figure {
            position: relative;
            margin: 0px;
            display: inline;

            picture {
                position: relative;
                display: inline;

                img {
                    height: var(--height);
                    width: var(--width);
                    display: block;
                }
            }
        }
    }
}

.form-group {
    //margin-bottom: 1rem;
    > * {
        margin: 0 0.313rem;
    }
}

.search-group {
    position: relative;

    button {
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
        width: 44px;
        height: 44px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;

        &.search {
            right: 0;
        }

        &.clear {
            right: 35px;
        }
    }

    input {
        width: 100%;
        height: 2.75rem;
        max-height: 2.75rem;
        border-radius: 0.188rem;
        border: 0.125rem solid #8F9192;
        padding-right: 60px;
    }
}

.is-overridden {
    color: orange;
}

.pi.is-overridden {
    color: orange;
    font-size: 1.5rem;
    cursor: pointer;
    padding-right: 5px;
}

.download-link {
    margin-bottom: 1rem;
}

.p-tooltip.wider-tooltip .p-tooltip-text {
    width: 260px;
}

.cbms-menu-container {
    list-style: none;
    margin: 0;
    padding: 0;
}

.cbms-menu-item {
    display: flex;
    height: 51px;
    padding: 0 10px 0 20px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer;

    &:hover {
        background-color: #f6f6f7;
        padding-left: 15px;
        border-left: 5px solid #1ea2b1;
    }

    &:has(.selected) {
        background-color: #f6f6f7;
        padding-left: 15px;
        font-weight: 600;
        border-left: 5px solid #1ea2b1;
    }

    a {
        line-height: 51px;
        text-align: left;
        font-size: 16px;
        color: #39393a;
        text-transform: uppercase;
        letter-spacing: .03rem;
        flex: auto;
        text-decoration: none;
        align-self: center;
        outline: none;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@media (min-width: $screen-sm) {
    .app-header {
        .main-nav-link {
            a {
                padding: 1.25rem 1rem 1.125rem 1rem;
                font-size: 1.125rem;
            }
        }
    }

    .container {
        width: $container-sm;
    }

    .search-wrapper {
        max-width: calc($container-sm - 1rem);
    }

    .detailed-entity-card {
        border-radius: 0.5rem;

        .entity-card-header {
            padding: 1rem 2rem 1rem 1rem;
        }

        .entity-card-content {
            padding: 1rem;

            .first-col {
                width: 45%;
            }

            .mid-col {

                span.kpi-unit {
                    width: auto;
                    min-width: 2rem;
                }

                span.icon-btn {
                    width: auto;
                    min-width: 2.5rem;
                }
            }

            .last-col {
                display: block;
                min-width: 1.5rem;
                text-align: right;
            }
        }

        .entity-card-footer {
            padding: 1rem 2rem;
        }
    }
    // .min-width-popup {
    //     min-width: auto;
    // }
}

@media (min-width: $screen-md) {
    .container {
        width: $container-md;
    }

    .search-wrapper {
        max-width: calc($container-md - 1rem);
    }

    .detailed-entity-card {
        .entity-card-content {
            .first-col {
                width: auto;
            }
        }
    }

    .download-link {
        margin-left: auto;
    }

}

@media (min-width: $screen-lg) {
    .app-header {
        .logo {
            margin: 0.75rem 1.625rem 0.75rem 0;
        }

        .main-nav-link {
            &.mobile-navigation {
                display: none;
            }

            &.desktop-navigation {
                display: inline;
            }
        }
    }

    .container {
        width: $container-lg;
        padding: 0;
    }

    .search-wrapper {
        max-width: $container-lg;
    }
}

.entity-card {
    .entity-card-content {
        flex-direction: column;
        align-items: flex-start;

        .entity-icon {
            margin-bottom: 1rem;
        }

        .entity-details {
            flex-grow: 1;
            width: 100%;

            .entity-name {
                font-size: 1.5rem;
                line-height: 1.5rem;
                font-weight: 700;
                margin-bottom: 0.5rem;
                overflow-wrap: break-word;
                word-break: break-word;
                width: 90%;
            }

            .entity-class {
                font-size: 1.25rem;
                line-height: 1.5rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 90%;
            }
        }
    }
}

@media (min-width: $screen-xl) {
    .entity-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .main-wrapper {
        margin-top: 0;
    }

    .container {
        width: $container-xl;
        justify-content: flex-start;
    }

    .search-wrapper {
        max-width: $container-xl;
    }

    .mobile-view {
        display: none;
    }

    .desktop-view {
        display: flex;
        & > div {
            width: 16rem;
            max-width: 16rem;
        }
    }

    .sub-navigation {
        .grouped-buttons {
            button {
                font-size: 1.125rem;
                min-width: 6.3rem;
            }
        }
    }

    .app-header {

        .logo {
            // margin: 0.75rem 3rem 0.75rem 0;
            margin: 0;
            padding-right: 3rem;
            border-right: 0.063rem solid #fff;
            display: flex;
            align-items: center;

            span {
                display: block;
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 1.5rem;
                letter-spacing: 0;
                text-align: left;
                margin-left: 0.75rem;
            }
        }

        .app-header-right {
            // width: 25rem;
            width: fit-content;
            margin-top: 0.5rem;

            .hamburger-menu {
                display: none;
            }

            .site-and-support {
                display: flex;
            }
        }

    }

    .app-sub-header {

        .mobile-navigation {
            display: none;
        }

        .desktop-navigation {
            display: flex;
            //width: 36.625rem;
        }
    }

    .search-wrapper {
        display: none;
    }

    .search-group {
        input {
            width: 17.5rem;
        }
    }

    .custom-dropdown {
        display: inline-block;
        align-items: center;
        min-width: 10rem;
        width: 10rem;
        & > div {
            height: inherit;
            width: inherit;
            & > span {
                height: inherit;
                width: inherit;
                border: none;
            }
        }
        .p-inputtext[role='searchbox']:focus {
            box-shadow: 0 0 0 0.2rem #8dcdff;
        }
    }
}

body .p-button.cbms-site-filter-button {
    background: none !important;
    border: 1px solid #a6a6a6 !important;
    color: #000 !important;
    font-weight: 700;
    font-size: 16px;
    text-transform: capitalize;
}

.cbms-label-small-text {
    .p-checkbox-label,
    .p-radiobutton-label {
        font-size: 12px !important;
    }
}
.btn-info {
    color: $eon-white;
    border: none;
    background-color: $eon-darkgrey-26 !important;
    &:hover {
        background-color: $eon-darkgrey-27 !important;
    }
}


.alert-badge {
    border-radius: 16px;
    padding: 4px 8px;
}

.alert-high-priority {
    color: #FF2A2A;
    background-color: rgba(255, 42, 42, 0.05);
}

.alert-medium-priority {
    color: #EB8700;
    background-color: rgba(235, 135, 0, 0.05);
}

.alert-low-priority {
    color: #00A58C;
    background-color: rgba(0, 165, 140, 0.05);
}

.no-alerts {
    color: #000000;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 8px;
}
.min-width-popup {
    min-width: 600px;
}
.hide-item {
    display: none;
}
.button-disabled {
    background-color: #89b1b5 !important;
}
.button-disabled:hover {
    cursor: not-allowed;
}
